import React, { useEffect } from "react";
import othersImg from "../assets/images/others.svg";
import { useNavigate, useSearchParams } from "react-router-dom";

function AccountActions() {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  useEffect(() => {
    const mode = searchParams.get("mode");

    // https://firebase.google.com/docs/auth/custom-email-handler
    switch (mode) {
      case "verifyEmail":
        navigate(`/verify-user/?${searchParams}`);
        break;
      case "resetPassword":
        navigate(`/reset-password/?${searchParams}`);
        break;
      default:
        navigate("/");
        break;
    }
  }, []);

  return (
    <React.Fragment>
      <div className="App">
        <img src={othersImg} className="othersImgSmall" alt="otherspng" />
        <p className="titleText">Please wait...</p>
      </div>
    </React.Fragment>
  );
}

export default AccountActions;
