const SvgComponent = (props) => (
  <svg
    viewBox="0 0 40 40"
    fill="none"
    {...props}
  >
    <path
      fill={props.color || "#EFEFEF"}
      d="M31.838 12.352a4.162 4.162 0 1 0 0-8.323 4.162 4.162 0 0 0 0 8.323ZM8.175 24.184a4.162 4.162 0 1 0 0-8.323 4.162 4.162 0 0 0 0 8.323ZM31.838 36.015a4.162 4.162 0 1 0 0-8.323 4.162 4.162 0 0 0 0 8.323Z"
    />
    <path
      fill={props.color || "#EFEFEF"}
      d="M20.252 24.177a4.166 4.166 0 0 0 3.916-4.155v-.02a4.166 4.166 0 0 0-3.868-4.15c-.079 0-.157 0-.235-.01a8.21 8.21 0 0 1-7.73-7.735c0-.075-.007-.149-.009-.224a4.167 4.167 0 1 0-4.384 4.442l.125.005a8.207 8.207 0 0 1 7.77 7.695 8.21 8.21 0 0 1-7.73 7.67c-.075 0-.149.006-.224.008a4.165 4.165 0 1 0 4.447 4.258 8.21 8.21 0 0 1 7.766-7.774l.156-.01Z"
    />
    <path
      fill={props.color || "#EFEFEF"}
      d="M32.083 24.177A4.166 4.166 0 0 0 36 20.022v-.02a4.165 4.165 0 0 0-3.868-4.15c-.078 0-.156 0-.235-.01a8.21 8.21 0 0 1-7.73-7.735c0-.075-.006-.149-.009-.224a4.165 4.165 0 1 0-4.384 4.442l.125.005a8.207 8.207 0 0 1 7.77 7.695 8.21 8.21 0 0 1-7.729 7.67c-.075 0-.15.006-.224.008a4.166 4.166 0 1 0 4.442 4.383v-.125a8.21 8.21 0 0 1 7.766-7.774c.06-.005.11-.007.16-.01Z"
    />
  </svg>
)
export default SvgComponent
