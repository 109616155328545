const SvgComponent = (props) => (
  <svg
    viewBox="0 0 40 40"
    fill="none"
    {...props}
  >
    <path
      fill={props.color || "#EFEFEF"}
      d="M20.042 36.088a4.173 4.173 0 1 0 0-8.345 4.173 4.173 0 0 0 0 8.345ZM20 12.332a4.16 4.16 0 1 0 .001-8.322 4.16 4.16 0 0 0 0 8.322Z"
    />
    <path
      fill={props.color || "#EFEFEF"}
      d="M32.124 27.68c-.078 0-.156 0-.234-.01a8.212 8.212 0 0 1-7.523-6.31 2.844 2.844 0 0 1 4.014.964 4.165 4.165 0 1 0 0-4.658 2.844 2.844 0 0 1-4.012.973 8.209 8.209 0 0 1 7.556-6.309c.05 0 .102 0 .152-.007a4.165 4.165 0 1 0-4.397-4.444c0 .078 0 .156-.01.234a8.206 8.206 0 0 1-7.67 7.72 8.206 8.206 0 0 1-7.67-7.728c0-.074-.008-.148-.01-.223a4.165 4.165 0 1 0-4.381 4.444l.125.005a8.21 8.21 0 0 1 7.569 6.309 2.844 2.844 0 0 1-3.88-.761 4.166 4.166 0 1 0-.131 4.455 2.845 2.845 0 0 1 4.014-.963 8.212 8.212 0 0 1-7.528 6.31c-.074 0-.149.007-.224.009a4.165 4.165 0 1 0 4.441 4.383c0-.042 0-.084.005-.125a8.206 8.206 0 0 1 7.668-7.766 8.205 8.205 0 0 1 7.672 7.757 4.166 4.166 0 1 0 4.455-4.259Z"
    />
  </svg>
)
export default SvgComponent