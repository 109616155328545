import React, { useState } from "react";
import { Link, createSearchParams, useNavigate } from "react-router-dom";
import { registerWithEmailPassword } from "../utils/auth";
import othersImgSmall from "../assets/images/others.svg";

function SignUp(props) {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [isPasswordValid, setIsPasswordValid] = useState(true); // add state variable
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);

  const navigate = useNavigate();

  const handleEmailChange = (event) => setEmail(event.target.value);
  const handlePasswordChange = (event) => {
    setPassword(event.target.value);
    setIsPasswordValid(validatePassword(event.target.value)); // update isPasswordValid state
  };

  const validatePassword = (password) => {
    //validate password and return true or false
    const passwordRegex = /^[A-Za-z\d`~!@#$%^&*()_+={}[\]:;"'<>,.?/\\|-]{8,}$/;
    return passwordRegex.test(password);
  };

  async function signUp() {
    setIsLoading(true);
    try {
      await registerWithEmailPassword(email.toLowerCase(), password);

      const buttonEl = document.getElementById("signupButton");
      buttonEl.classList.add("loading");
      setTimeout(() => {
        buttonEl.classList.remove("loading");
        buttonEl.classList.add("success");
        buttonEl.innerText = "Success";
        setTimeout(() => {
          navigate({
            pathname: "/signUp/complete",
            search: createSearchParams({
              email: email,
            }).toString(),
          });
        }, 1500);
      }, 1000);
      return true;
    } catch (error) {
      console.log("Error signing up", error);
      // if (error.response && error.response.status === 400) {
      //   const { username, email, password } = error.response.data;
      //   let errorMessages = [];
      //   if (username) {
      //     errorMessages.push(`${username}`);
      //   }
      //   if (email) {
      //     errorMessages.push(`${email}`);
      //   }
      //   if (password) {
      //     errorMessages.push(`${password}`);
      //   }
      //   setError(errorMessages);
      // } else {
        setError("An error occurred. Please try again.");
      // }
    } finally {
      setIsLoading(false);
    }
  }

  return (
    <React.Fragment>
      <div className="App">
        <img src={othersImgSmall} className="othersImgSmall" alt="otherspng" />
        <div className="titleText smallTitle">Create Account</div>
        <div id="signUpCredentials" className="signUpCredentials">
          <div className="secondaryText email2">Email</div>
          <div className="emailBox">
            <input
              className="mainText box"
              type="email"
              id="email"
              name="email"
              value={email}
              onChange={handleEmailChange}
            />
          </div>
          <div className="secondaryText password2">Password</div>
          <div className="passwordBox">
            <input
              className="mainText box"
              type="password"
              id="password"
              name="password"
              value={password}
              onChange={handlePasswordChange}
            />
          </div>
          <div className="secondaryText terms">
            By tapping "Create Account" you agree to the <br />
            <a href="./terms-and-conditions.html">Terms & Conditions</a>
          </div>
        </div>
        <div className="errorContainer">
          {!isPasswordValid && (
            <div className="errorMessage">
              Password must be at least 8 characters long
            </div>
          )}
          {error && <div className="errorMessage">{error}</div>}
        </div>
        <button
          id="signupButton"
          className="submitButton mainText"
          onClick={signUp}
          disabled={isLoading}
        >
          <span>Create Account</span>
          {/* <span className="spinner"></span> */}
        </button>
        <div className="bottomInfo">
          <div className="secondaryText">Already have an account?</div>
          <Link to="/login">
            <button className="signUp mainText">Sign In</button>
          </Link>
        </div>
      </div>
    </React.Fragment>
  );
}

export default SignUp;
