import React, { Component } from 'react';
import othersImg from '../assets/images/others.svg';

export default class SignUpComplete extends Component {
  render() {
    return(
      <div className='App'>
      <div>
        <div><img src={othersImg} className="othersImgSmall" alt="otherspng" /></div>
        <div className="titleText smallTitle">Verify Your Email </div>
        <div className='subscription'>
        <p className='mainText'>An email with a verification link has been sent to the address you provided.</p>
        <a className="mainText" href="/login">Return Home</a>
        </div>
      </div>
      </div>
    )
  }
}