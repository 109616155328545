import "./App.css";

import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Login from "./screens/Login.jsx";
import SignUp from "./screens/SignUp.jsx";
import SignUpComplete from "./screens/SignUpComplete.jsx";
import Verify from "./screens/Verify.jsx";
import SubscriptionsPage from "./screens/SubscriptionPage.jsx";
import Redeem from "./screens/Redeem.jsx";
import ForgotPasswordLink from "./screens/ForgotPasswordLink.jsx";
import ResetPassword from "./screens/ResetPassword.jsx";
import ShareTrack from "./screens/ShareTrack.jsx";
import ShareAlbum from "./screens/ShareAlbum.jsx";

import "./utils/axios.js";
import './assets/styles/style.css';
import DeleteAccount from "./screens/DeleteAccount.jsx";
import AccountActions from "./screens/AccountActions.jsx";

const appStyle = { backgroundColor: "black" };

function App() {
  return (
    <Router>
      <div className="App" style={appStyle}>
        <Routes>
          <Route path="/" Component={SubscriptionsPage} />
          <Route path="/login" Component={Login} />
          <Route path="/welcome" Component={SignUp} />
          <Route path="/signup" Component={SignUp} />
          <Route path="/signup/complete" Component={SignUpComplete} />
          <Route path="/verify-user" Component={Verify} />
          <Route path="/redeem" Component={Redeem} />
          <Route path="/reset-password-link" Component={ForgotPasswordLink} />
          <Route path="/reset-password" Component={ResetPassword} />
          {/* TODO unite ShareAlbum and ShareTrack */}
          <Route path="/share/track" Component={ShareTrack} />
          <Route path="/share/album" Component={ShareAlbum} />
          <Route path="/delete-account" Component={DeleteAccount} />
          <Route path="/account-actions" Component={AccountActions} />
        </Routes>
      </div>
    </Router>
  );
}

export default App;
