const SvgComponent = (props) => (
  <svg
    viewBox="0 0 40 40"
    fill="none"
    {...props}
  >
    <path
      fill={props.color || "#EFEFEF"}
      d="M8.17 12.354a4.162 4.162 0 1 0 0-8.324 4.162 4.162 0 0 0 0 8.324ZM31.835 12.353a4.162 4.162 0 1 0 0-8.324 4.162 4.162 0 0 0 0 8.324ZM31.838 36.016a4.162 4.162 0 1 0 0-8.324 4.162 4.162 0 0 0 0 8.324Z"
    />
    <path
      fill={props.color || "#EFEFEF"}
      d="M7.924 15.85a4.165 4.165 0 1 0 4.398 4.447c0-.078.005-.156.009-.234a8.21 8.21 0 0 1 7.731-7.73c.075 0 .15-.009.224-.01a4.166 4.166 0 1 0-4.442-4.384v.125a8.21 8.21 0 0 1-7.92 7.785Z"
    />
    <path
      fill={props.color || "#EFEFEF"}
      d="M20.29 24.176a4.167 4.167 0 1 0-4.442-4.384v.124a8.21 8.21 0 0 1-7.775 7.78l-.153.007a4.166 4.166 0 1 0 4.402 4.452c0-.078.005-.156.009-.235a8.211 8.211 0 0 1 7.959-7.744Z"
    />
    <path
      fill={props.color || "#EFEFEF"}
      d="M31.836 15.863a4.166 4.166 0 0 0-4.153 3.929c0 .041 0 .083-.005.125a8.209 8.209 0 0 1-7.766 7.775l-.152.007a4.165 4.165 0 1 0 4.394 4.456A8.21 8.21 0 0 1 31.9 24.19c.074 0 .15-.007.223-.009a4.166 4.166 0 0 0-.286-8.314v-.005Z"
    />
  </svg>
)
export default SvgComponent