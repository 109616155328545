import React, { useEffect, useState } from "react";
import { Link, useSearchParams } from "react-router-dom";

import othersImg from "../assets/images/others.svg";
import { auth } from "../utils/firebase";
import { confirmPasswordReset, verifyPasswordResetCode } from "firebase/auth";

const ResetPasswordState = {
  Loading: 0,
  Form: 1,
  Success: 2,
  InvalidCode: 3,
};

function ResetPassword() {
  const [newPassword, setNewPassword] = useState("");
  const [error, setError] = useState(null);
  const [step, setStep] = useState(ResetPasswordState.Loading);
  const handleNewPasswordChange = (event) => setNewPassword(event.target.value);

  const [searchParams] = useSearchParams();
  const code = searchParams.get("oobCode");

  const setErrorMessage = (error) => {
    if (error.response && error.response.data && error.response.data.password) {
      setError(error.response.data.password[0]);
    } else if (
      error.response &&
      error.response.data &&
      error.response.data.detail
    ) {
      if (error.response.data.detail === "Invalid signature") {
        setError("Password reset link has expired.");
      }
    } else {
      setError("Unknown error occurred");
    }
  };

  const handleResetPassword = async (event) => {
    event.preventDefault();

    try {
      await confirmPasswordReset(auth, code, newPassword);

      setStep(ResetPasswordState.Success);
    } catch (error) {
      setErrorMessage(error);
    }
  };

  const isValidPassword = (newPassword) => {
    const passwordRegex = /^[A-Za-z\d`~!@#$%^&*()_+={}[\]:;"'<>,.?/\\|-]{8,}$/;
    return passwordRegex.test(newPassword);
  };

  useEffect(() => {
    // Check if the firebase reset code is valid
    const checkIfCodeIsValid = async () => {
      try {
        await verifyPasswordResetCode(auth, code);
        setStep(ResetPasswordState.Form);
      } catch (e) {
        setStep(ResetPasswordState.InvalidCode);
      }
    };

    checkIfCodeIsValid();
  }, []);

  const renderForm = () => {
    return (
      <form className="forgotPasswordForm" onSubmit={handleResetPassword}>
        <div id="credentials" className="credentials">
          <div className="secondaryText password">Password</div>
          <div className="passwordBox">
            <input
              type="password"
              className="mainText box"
              id="newPassword"
              value={newPassword}
              onChange={handleNewPasswordChange}
            />
          </div>
          {error && <div className="errorMessage">{error}</div>}
          {newPassword !== "" && !isValidPassword(newPassword) && (
            <div className="errorMessage">
              Password must be at least 8 characters long
            </div>
          )}
        </div>
        <button type="submit" className="resetPasswordButton">
          Reset Password
        </button>
      </form>
    );
  };

  const renderSuccess = () => {
    return (
      <>
        <div className="successMessage">Your password has been reset.</div>
        <Link to="/login">
          <button className="submitButton mainText">Return</button>
        </Link>
      </>
    );
  };

  const renderContent = () => {
    switch (step) {
      case ResetPasswordState.Loading:
        return <div className="mainText">Please wait...</div>;
      case ResetPasswordState.Form:
        return renderForm();
      case ResetPasswordState.Success:
        return renderSuccess();
      case ResetPasswordState.InvalidCode:
        return (
          <div className="mainText">
            This link is invalid. Please try to reset your password again.
          </div>
        );
    }
  };

  return (
    <>
      <div className="App">
        <img src={othersImg} className="othersImgSmall" alt="otherspng" />
        <div className="titleText smallTitle">Password Reset</div>
        <div className="container">{renderContent()}</div>
      </div>
    </>
  );
}

export default ResetPassword;
