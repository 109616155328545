import React, { useState } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import othersImg from "../assets/images/others.svg";
import xButton from "../assets/images/X button.png";

const ForgotPasswordState = {
  EmailForm: 0,
  Success: 1,
};

function ForgotPasswordLink() {
  const [email, setEmail] = useState("");
  const [error, setError] = useState(null);
  const [step, setStep] = useState(ForgotPasswordState.EmailForm);

  const handleEmailChange = (event) => setEmail(event.target.value);

  const handleSendCode = async (event) => {
    event.preventDefault();
    try {
      const csrfRes = await axios.get("/v1/csrf/", {
        withCredentials: true,
      });
      const lowerCaseEmail = email.toLowerCase();
      const response = await axios.post(
        "/v2/reset_password/",
        {
          email: lowerCaseEmail,
        },
        {
          headers: {
            "X-CSRFToken": csrfRes.data.csrfToken,
          },
        }
      );
      console.log("Forgot password response:", response);
      setStep(ForgotPasswordState.Success);
    } catch (error) {
      console.log("Error sending forgot password link", error);
      setError("Error sending forgot password link. Please try again.");
      setTimeout(() => {
        setError(null);
      }, 5000); // reset error state after 5 seconds
    }
  };

  const renderEmailForm = () => {
    return (
      <>
        <div className="App">
          <img src={othersImg} className="othersImgSmall" alt="otherspng" />
          <a href="/login">
            <button id="xButton" className="xButton">
              <img src={xButton} className="xButtonImg" alt="xPng" />
            </button>
          </a>
          <div className="titleText smallTitle">Password Reset</div>
          <form className="forgotPasswordForm" onSubmit={handleSendCode}>
            <div id="credentials" className="credentials">
              <div className="secondaryText email">Email</div>
              <div className="emailBox">
                <input
                  type="email"
                  className="mainText box"
                  id="email"
                  value={email}
                  onChange={handleEmailChange}
                />
              </div>
              {error && <div className="errorMessage">{error}</div>}
            </div>
            <button type="submit" className="submitButton mainText">
              Send Email
            </button>
          </form>
        </div>
      </>
    );
  };

  const renderSuccess = () => {
    return (
      <>
        <div className="App">
          <img src={othersImg} className="othersImgSmall" alt="otherspng" />
          <div className="titleText smallTitle">Password Reset</div>
          <div className="successMessage">
            An email with a link for password reset has been sent to your email.
          </div>
          <Link to="/login">
            <button className="submitButton mainText">Return</button>
          </Link>
        </div>
      </>
    );
  };

  return (
    <div className="forgotPassword">
      {step === ForgotPasswordState.EmailForm
        ? renderEmailForm()
        : renderSuccess()}
    </div>
  );
}

export default ForgotPasswordLink;
