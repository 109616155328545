import React, { useState, useRef } from "react";
import { Link, useNavigate } from "react-router-dom";
import { getAuth } from "firebase/auth";
import { login, LoginProvider } from "../utils/auth";
import othersImg from "../assets/images/others.svg";
import {
  AppleLoginButton,
  FacebookLoginButton,
  GoogleLoginButton,
} from "react-social-login-buttons";

function Login() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState(null);
  const navigate = useNavigate();
  const inputRef = useRef();

  const handleEmailChange = (event) => setEmail(event.target.value);
  const handlePasswordChange = (event) => setPassword(event.target.value);

  const handleKeyEvent = async (event) => {
    if (event.key === "Enter" && event.target.tagName === "INPUT") {
      event.preventDefault();
      await handleLogin(LoginProvider.EmailPassword);
    }
  };

  const handleLogin = async (provider) => {
    try {
      await login(provider, email.toLowerCase(), password);
      // const auth = getAuth();
      // if (!auth.currentUser.emailVerified && provider === LoginProvider.EmailPassword) {
      //   setError("Please check your email to verify your account.");
      //   return; 
      // }

      navigate("/");
    } catch (error) {
      if (error.message.includes("auth/wrong-password")) {
        setError("Invalid email or password. Please try again.");
      } else {
        setError("Something went wrong. Please try again.");
      }
    }
  };

  return (
    <React.Fragment>
      <div className="App">
        <img src={othersImg} className="othersImgSmall" alt="otherspng" />
        <div className="titleText smallTitle">Login</div>
        <div
          id="credentials"
          className="credentials"
          ref={inputRef}
          onKeyDown={handleKeyEvent}
        >
          <div className="secondaryText email">Email</div>
          <div className="emailBox">
            <input
              className="mainText box"
              type="email"
              id="email"
              name="email"
              onInput={handleEmailChange}
              onChange={handleEmailChange}
              value={email}
            />
          </div>
          <div className="secondaryText password">Password</div>
          <div className="passwordBox">
            <input
              className="mainText box"
              type="password"
              id="password"
              name="password"
              onInput={handlePasswordChange}
              onChange={handlePasswordChange}
              value={password}
            />
          </div>
          <Link to={{ pathname: "/reset-password-link" }}>
            <button className="secondaryText forgot">Forgot Password?</button>
          </Link>
          {error && <div className="errorMessage">{error}</div>}
        </div>

        <div className="social-buttons">
          <button
            className="submitButton mainText"
            onClick={() => {
              handleLogin(LoginProvider.EmailPassword);
            }}
          >
            Login
          </button>

          <p>Or continue with one of the following:</p>

          <GoogleLoginButton
            text="Continue with Google"
            onClick={() => {
              handleLogin(LoginProvider.Google);
            }}
          />
          <FacebookLoginButton
            text="Continue with Facebook"
            onClick={() => {
              handleLogin(LoginProvider.Facebook);
            }}
          />
          <AppleLoginButton
            text="Continue with Apple"
            onClick={() => {
              handleLogin(LoginProvider.Apple);
            }}
          />
        </div>

        <div className="bottomInfo">
          <div className="secondaryText">Don't have an account?</div>
          <Link to="/signUp">
            <button className="signUp mainText">Sign Up</button>
          </Link>
        </div>
      </div>
    </React.Fragment>
  );
}

export default Login;
