import React, { useEffect, useState } from 'react';
import axios from 'axios';
import othersImg from '../assets/images/others.svg';
import othersImgCropped from '../assets/images/othersCropped.svg'
import AppStoreBadge from '../assets/svgs/AppStoreBadge';

import GooglePlayPng from '../assets/images/GooglePlayPng.png';
import PonderIcon from '../assets/svgs/PonderIcon.jsx';
import SoundscapeIcon from '../assets/svgs/SoundscapesIcon.jsx';
import BreathworkIcon from '../assets/svgs/BreathworkIcon.jsx';
import MeditationIcon from '../assets/svgs/MeditationIcon.jsx';

import { BASE_URL_PREFIX } from '../config.js';
import { useNavigate } from 'react-router-dom';

const descriptionAlt = 'These meditations help guide the mind to places where we can open up and connect to our inner knowing, taking you on a journey of sound, emotion and resonance.'

const cta = 'Download our app and unlock the full possibilities of the ØTHERS side.'

const freeTrial = 'Get a free trial, no strings attached.'

function Artist(artist) {
  return (
  <div className='artistContainer'>
    <img src={artist?.artist?.artist_thumbnail_url} alt='artistThumbnail' className='artistThumbnailImage'/>
    <span className='shareArtist'>{artist?.artist?.artist_name}</span>
  </div>
  )
}

function ShareAlbum(props) {
  const urlParams = new URLSearchParams(window.location.search);
  const albumId = urlParams.get('album_id');

  const [data, setData] = useState(null);

  const [error, setError] = useState(null);

  const navigate = useNavigate();

  useEffect(() => {
    const fetchTrack = async () => {

      const options = {
        method: 'GET',
        url: `${BASE_URL_PREFIX}/share/album/?album_id=${albumId}`
      };
  
      axios
        .request(options)
        .then(function (response) {
          setData(response.data);
        })
        .catch(function (error) {
          setError(error);
        });
  
    }

    fetchTrack();
  }, [albumId]);

  if (error) {
    navigate('/');
  }

  function renderIcon(className, color) {
    switch (data?.type) {
      case 'ponder':
        return <PonderIcon className={className} color={color}/>;
      case 'soundscape': 
        return <SoundscapeIcon className={className} color={color}/>;
      case 'breathwork':
        return <BreathworkIcon className={className} color={color}/>;
      case 'meditation':
        return <MeditationIcon className={className} color={color}/>;
      default: 
        return <MeditationIcon className={className} color={color}/>;
    }
  }

  function tag() {
    switch (data?.type) {
      case 'ponder':
        return 'PONDER';
      case 'soundscape':
        return 'SOUNDSCAPES';
      case 'breathwork':
        return 'BREATHWORK';
      case 'meditation':
        return 'MEDITATIONS';
      default:
        return 'MEDITATIONS';
    }
  }

  function renderContent() {
    return error ? 
      (<React.Fragment>
        <p>Something went wrong</p>
      </React.Fragment>) :
      (<React.Fragment>
        <div className='shareContainer'>
          <div className='thumbnailContainer'>
            <img src={othersImgCropped} className='othersImgBackground' alt='otherspng' />
            <img src={data?.thumbnail_url} alt='thumbnail' className='thumbnailImage'/>
            <div className='bottomLeftDetailsContainer'>
              <div className='detailsRow'>
                {renderIcon('bigTagIcon', '#000')}
                <span className='detailsText'>{data?.artists?.map(artist => artist.artist_name).join(', ')} {'\u25CF'} {data?.album_title}</span>
              </div>
              {/* TODO add num of tracks + duration */}
              <span className='subDetailsText'></span>
            </div>
          </div>
          <div className='card'>
            <div className='cardTop'>
              <span className='shareTitle'>{data?.album_title}</span>
              {data?.artists?.map(artist => <Artist artist={artist}/>)}
              {data?.album_description !== null && 
                <span className='shareDescription'>{data?.album_description || descriptionAlt}</span>
              }
              <div className='insideCardDetailsContainer'>
                <div className='detailsRow'>
                  {renderIcon('bigTagIcon', '#000')}
                  <span className='detailsText'>{data?.artists?.map(artist => artist.artist_name).join(', ')} {'\u25CF'} {data?.album_title}</span>
                </div>
                {/* TODO add num of tracks + duration */}
                <span className='subDetailsText'></span>
              </div>
            </div>
            <div className='cardBottom'>
              <span className='ctaShareText'>{cta}</span>
              <div className='badgesContainer'>
                {/* TODO update link */}
                <a href='https://play.google.com/store/apps/details?id=co.others.app'>
                  <img src={GooglePlayPng} alt='google play badge' className='badge'/>
                </a>
                {/* TODO update link */}
                <a href='https://apps.apple.com/us/app/others/id1615837655'>
                  <AppStoreBadge className='badge'/>
                </a>
              </div>
              <span className='freeTrialText'>{freeTrial}</span>
            </div>
            <div className='topRightTagContainer'>
              <span className='mediaType'>Album</span>
              <div className='tagContainer'>
                {renderIcon('tagIcon')}
                <span className='tag'>{tag()}</span>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>);
  }

  return (
    <React.Fragment>
      <div className='App'>
        <div className={'shareHeader'}>
          <img src={othersImg} className='othersImgShare' alt='otherspng' />
          <a className='getToKnowUsLink' href='https://others.co/'>
            <div className='getToKnowUsContainer'>
              <span className='getToKnowUs'>GET TO KNOW US</span>
            </div>
          </a>
        </div>
          
        {renderContent()}
        
      </div>
    </React.Fragment>
  );
}

export default ShareAlbum;