import React from "react";
import othersImg from "../assets/images/others.svg";

function DeleteAccount(props) {
  const steps = [
    "Open the App",
    "Go to Settings by pressing the cog icon on the top right",
    "Press 'Delete Account' on the bottom right",
    "You have successfully deleted your account",
  ];

  const renderSteps = steps.map((step) => (
    <li key={step} className="deleteAccountStep">
      {step}
    </li>
  ));

  return (
    <React.Fragment>
      <div className="App">
        <img src={othersImg} className="othersImgSmall" alt="otherspng" />
        <p className="titleText">How to delete your account</p>
        <div className="deleteAccountListContainer">
          <ol>{renderSteps}</ol>
        </div>
      </div>
    </React.Fragment>
  );
}

export default DeleteAccount;
