import axios from 'axios';
import {BASE_URL} from '../config';

// setup axios with some defaults
axios.defaults = Object.assign(axios.defaults, {
  withCredentials: true,
  baseURL: BASE_URL,
  xsrfCookieName: 'csrftoken',
  xsrfHeaderName: "X-CSRFTOKEN"
});

// Add a response interceptor
axios.interceptors.response.use(function (response) {
  // Do something with response data
  return response;
}, function (error) {
  // Do something with response error
  if(error?.response?.status === 401 || error?.response?.status === 403) {
    //console.log('here we can redirect to Login screen');
    window.location.replace('/login');
  }
  return Promise.reject(error);
});