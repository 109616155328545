import React, { useState, useEffect } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { applyActionCode } from "firebase/auth";
import othersImg from "../assets/images/others.svg";
import { auth } from "../utils/firebase";
import axios from "axios";

function Verify() {
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();

  const [message, setMessage] = useState("");

  // this is called like componentDidMount()
  useEffect(() => {
    const callApi = async () => {
      setMessage("Verifying your emaill address...");

      try {
        const oobCode = searchParams.get("oobCode");
        await applyActionCode(auth, oobCode);

        // All users are marked as active from the backend for now
        // const csrfRes = await axios.get("/v1/csrf/", {
        //   withCredentials: true,
        // });
        // const csrfToken = csrfRes.data.csrfToken;

        // await axios.post("/v2/verify_account/", {
        //   headers: {
        //     "X-CSRFToken": csrfToken,
        //   },
        //   withCredentials: true,
        // });

        setMessage(
          "Verification successful.\n\n You are being redirected to the ØTHERS side... "
        );
        navigate("/");
      } catch (error) {
        setMessage("Something went wrong. Please try again later.");
      }
    };
    callApi();
  }, []);

  return (
    <React.Fragment>
      <div className="App">
        <img src={othersImg} className="othersImgSmall" alt="logo" />
        <p className="subInfo verificationText">
          {message.split("\n").map((line, index) => (
            <React.Fragment key={index}>
              {line}
              <br />
            </React.Fragment>
          ))}
        </p>
      </div>
    </React.Fragment>
  );
}

export default Verify;
