import React, { useState, useRef } from 'react';
import { useNavigate } from "react-router-dom";
import axios from 'axios';
import othersImg from '../assets/images/others.svg';
import xButton from '../assets/images/X button.png';

function Redeem() {
  const [code, setCode] = useState(['', '', '', '', '', '']);
  const [showPopUp, setShowPopUp] = useState(false);
  const [error, setError] = useState('');
  const inputsRef = useRef([]);
  const nav = useNavigate();

  const handleCodeChange = (event, index) => {
    // Only allow letters and numbers in the code input boxes
    const newCode = [...code];
    const sanitizedValue = event.target.value.replace(/[^A-Za-z0-9]/g, '');
    newCode[index] = sanitizedValue;
    setCode(newCode);
    if (index < 5 && sanitizedValue.length === 1) {
      inputsRef.current[index + 1].focus();
    }
  };

  function handleKeyDown(event, index) {
    if (event.keyCode === 8 && !event.target.value) {
      event.preventDefault();
      if (inputsRef.current[index - 1]) {
        inputsRef.current[index - 1].focus();
      }
    }
  }

  async function handleSubmit(event) {
    event.preventDefault();

    // call the api
    try {
      const csrfRes = await axios.get('/v1/csrf/',
        {
          withCredentials: true
        }
      );

      const codeValue = code.join('');
      const redeemResponse = await axios.post('/v1/registration/', {
          type: 'uuid',
          uuid: codeValue
        }, 
        {
          headers: {
            'X-CSRFToken': csrfRes.data.csrfToken,
          },
          withCredentials: true
        }
      );
      // handle success
      console.log('UUID Registration: ' + redeemResponse);
      // go back to home
      nav('/');
    } 
    catch (error) {
      if (error.redeemResponse && error.redeemResponse.status === 400) {
        setError("Redeem Code was wrong, or already redeemed.");
      } else {
        setError("Redeem Code was wrong, or already redeemed.");
      }
      setCode(['', '', '', '', '', '']);
    }
  }

  function handlePopUpClose() {
    setShowPopUp(false);
  }

  return (
    <div className='App'>
    <div className="redeemScreen">
      <img src={othersImg} className="othersImgSmall" alt="Other logo" />
      <a href="./"><button className="xButton"><img src={xButton} className="xButtonImg" alt="Back" /></button></a>
      <div className="titleText smallTitle">Enter Your 6-Digit Code</div>
      <div className="secondaryText emailInfo">You can find your unique code under the QR code, on your product insert card.</div>
      <div className='formAndErrorContainer'>
      <form className="formContainer" onSubmit={handleSubmit}>
        <input className="codeBox" type="text" maxLength="1" value={code[0]} onChange={(event) => handleCodeChange(event, 0)} onKeyDown={(event) => handleKeyDown(event, 0)} ref={(el) => (inputsRef.current[0] = el)} />
        <input className="codeBox" type="text" maxLength="1" value={code[1]} onChange={(event) => handleCodeChange(event, 1)} onKeyDown={(event) => handleKeyDown(event, 1)} ref={(el) => (inputsRef.current[1] = el)} />
        <input className="codeBox" type="text" maxLength="1" value={code[2]} onChange={(event) => handleCodeChange(event, 2)} onKeyDown={(event) => handleKeyDown(event, 2)} ref={(el) => (inputsRef.current[2] = el)} />
        <input className="codeBox" type="text" maxLength="1" value={code[3]} onChange={(event) => handleCodeChange(event, 3)} onKeyDown={(event) => handleKeyDown(event, 3)} ref={(el) => (inputsRef.current[3] = el)} />
        <input className="codeBox" type="text" maxLength="1" value={code[4]} onChange={(event) => handleCodeChange(event, 4)} onKeyDown={(event) => handleKeyDown(event, 4)} ref={(el) => (inputsRef.current[4] = el)} />
        <input className="codeBox" type="text" maxLength="1" value={code[5]} onChange={(event) => handleCodeChange(event, 5)} onKeyDown={(event) => handleKeyDown(event, 5)} ref={(el) => (inputsRef.current[5] = el)} />
    </form>
    {error && <div className="errorMessage">{error}</div>}
    </div>
    <button type="submit" className="proceedButton" onClick={handleSubmit}>
        Submit
      </button>
      {showPopUp && (
        <div id="wrongPopUp" className="wrongPopUp">
          <div className="popUpTitle">Something went wrong!</div>
          <div className="popUpText">Redeem Code was wrong, or already redeemed. If any help is needed, please <button className="popUpButton">contact us</button></div>
          <button onClick={handlePopUpClose} className="tryAgainButton">Try Again</button>
        </div>
      )}
    </div>
    </div>
  );
}

export default Redeem;